import React from "react";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import Home from "./pages/Home";
import OverOns from "./pages/OverOns";
import Portfolio from "./pages/Portfolio";
import Contact from "./pages/Contact";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import store from "./redux/store";
import { changeSector } from "./redux/actions/actions";
import Edu from "./pages/edu/Edu";
import Workplace from "./pages/workplace/Workplace";
import CookieBanner from "./components/CookieBanner";
import Privacy from "./pages/Privacy";
import Error from "./pages/404";
import { Helmet } from "react-helmet";
import Vacancy2024 from "./pages/vacatures/vacancy2024";

function App() {
  const activeSector = useLocation().pathname.slice(1, 4);

  store.dispatch(
    changeSector(
      activeSector === "edu" ? "edu" : activeSector === "wor" ? "workplace" : "none"
    )
  );
  return (
    <div className="App">
      <Helmet
        encodeSpecialCharacters={true}
        titleTemplate="Oetang | %s"
        defaultTitle="Oetang Learning Designers"
      >
        <link rel="canonical" href="https://oetang.be" />
        <meta
          name="description"
          content="Oetang is een Learning Design Studio. We zijn gepassioneerd door alles wat met leren te maken heeft en zijn gespecialiseerd in Learning Experience Design."
        ></meta>
        <meta
          name="keywords"
          content="learning, leerervaring, design, leren, lxd, leerproduct, education, workplace, business, learning &amp; development, onderwijs, erfgoed, lifelong learning, learning experience design"
        ></meta>
      </Helmet>
      <Routes>
        <Route path="/edu/*" element={<Edu />}></Route>
        <Route path="/workplace/*" element={<Workplace />}></Route>
        <Route path="/home" element={<Home />} />
        <Route exact path="/" element={<Home />} />
        <Route path="/portfolio" element={<Portfolio />} />
        <Route path="/over-ons" element={<OverOns />} />
        <Route path="/jobs" element={<Vacancy2024 />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="*" element={<Error />} />
      </Routes>
      <CookieBanner></CookieBanner>
    </div>
  );
}

const mapStateToProps = (state) => ({
  eduHome: state.eduHome,
});

export default connect(mapStateToProps)(App);
