import React, { Component, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { closeMenu, toggleMenu } from "../../redux/actions/actions";
import aap from "../../assets/logos/logo-oetang_aap-groen-wit.svg";
import useOutsideClick from "../helpers/useOutsideClick";

const Menu = (props) => {
  const eduProducts = useSelector((state) => state.products.edu);
  const eduServices = useSelector((state) => state.services.edu);

  const workplaceProducts = useSelector((state) => state.products.job);
  const workplaceServices = useSelector((state) => state.services.job);
  const vacancies = useSelector((state) => state.vacancies.open.length);

  const isMenuOpen = useSelector((state) => state.isMenuOpen);
  const dispatch = useDispatch();

  return (
    <div
      className={`sidebar-menu${isMenuOpen === true ? " open" : ""}`}
    >
      <div
        className="menu"
        id="edu"
        style={
          props.clickedSector === "workplace"
            ? { display: "none" }
            : props.clickedSector === "mobile"
            ? { display: "none" }
            : { display: "block" }
        }
      >
        <div className="menu-header">
          <Link
            to={`/edu`}
            className="menu__title"
            onClick={() => dispatch(toggleMenu())}
          >
            Education
          </Link>

          <label className="hamburger" htmlFor="check">
            <input type="checkbox" id="check" checked readOnly />
            <span></span>
            <span></span>
            <span></span>
          </label>
        </div>

        <h3 className="menu__motto">
          Leerervaringen voor educatieve omgevingen.
        </h3>

        <div className="menu__wrapper">
          <div className="services-nav">
            <Link
              onClick={() => dispatch(toggleMenu())}
              to={`/edu`}
              className="services-nav__title"
            >
              Diensten
            </Link>

            <ul className="services-nav__menu">
              <li className="services-nav__menu-service services-nav__menu-service--research">
                <Link
                  onClick={() => dispatch(toggleMenu())}
                  to={`/edu/diensten/${eduServices[0].titleInCode}`}
                  className="services-nav__menu-service-title"
                >
                  <span
                    dangerouslySetInnerHTML={{ __html: eduServices[0].title }}
                  ></span>{" "}
                  <span className="services-nav__menu-service-baseline">
                    van leernoden
                  </span>
                </Link>
              </li>
              <li className="services-nav__menu-service services-nav__menu-service--strategy">
                <Link
                  onClick={() => dispatch(toggleMenu())}
                  to={`/edu/diensten/${eduServices[1].titleInCode}`}
                  className="services-nav__menu-service-title"
                >
                  <span
                    dangerouslySetInnerHTML={{ __html: eduServices[1].title }}
                  ></span>{" "}
                  <span className="services-nav__menu-service-baseline">
                    voor leerprocessen en -producten
                  </span>
                </Link>
              </li>
              <li className="services-nav__menu-service services-nav__menu-service--product-design">
                <Link
                  onClick={() => dispatch(toggleMenu())}
                  to={`/edu/diensten/${eduServices[2].titleInCode}`}
                  className="services-nav__menu-service-title"
                >
                  <span
                    dangerouslySetInnerHTML={{ __html: eduServices[2].title }}
                  ></span>{" "}
                  <span className="services-nav__menu-service-baseline">
                    van concept tot realisatie
                  </span>
                </Link>
              </li>
              <li className="services-nav__menu-service services-nav__menu-service--content-creation">
                <Link
                  onClick={() => dispatch(toggleMenu())}
                  to={`/edu/diensten/${eduServices[3].titleInCode}`}
                  className="services-nav__menu-service-title"
                >
                  <span
                    dangerouslySetInnerHTML={{ __html: eduServices[3].title }}
                  ></span>{" "}
                  <span className="services-nav__menu-service-baseline">
                    voor leerproducten
                  </span>
                </Link>
              </li>
              <li className="services-nav__menu-service services-nav__menu-service--implementation">
                <Link
                  onClick={() => dispatch(toggleMenu())}
                  to={`/edu/diensten/${eduServices[4].titleInCode}`}
                  className="services-nav__menu-service-title"
                >
                  <span
                    dangerouslySetInnerHTML={{ __html: eduServices[4].title }}
                  ></span>{" "}
                  <span className="services-nav__menu-service-baseline">
                    via coaching en training
                  </span>
                </Link>
              </li>
            </ul>

            <Link
              onClick={() => dispatch(toggleMenu())}
              to={`/edu`}
              className="services-nav__jumper"
            >
              <span className="btn-circle">
                <i className="icon-arrow-right"></i>
              </span>
              Alle diensten
            </Link>
          </div>

          <div className="products-nav">
            <Link
              onClick={() => dispatch(toggleMenu())}
              to={`/edu`}
              className="services-nav__title"
            >
              Leerproducten
            </Link>

            <ul className="products-nav__menu">
              <li className="products-nav__menu-product">
                <Link
                  onClick={() => dispatch(toggleMenu())}
                  to={`/edu/producten/${eduProducts[0].titleInCode}`}
                  className="products-nav__menu-product-title"
                  dangerouslySetInnerHTML={{ __html: eduProducts[0].title }}
                />
              </li>
              <li className="products-nav__menu-product">
                <Link
                  onClick={() => dispatch(toggleMenu())}
                  to={`/edu/producten/${eduProducts[1].titleInCode}`}
                  className="products-nav__menu-product-title"
                  dangerouslySetInnerHTML={{ __html: eduProducts[1].title }}
                />
              </li>
              <li className="products-nav__menu-product">
                <Link
                  onClick={() => dispatch(toggleMenu())}
                  to={`/edu/producten/${eduProducts[2].titleInCode}`}
                  className="products-nav__menu-product-title"
                  dangerouslySetInnerHTML={{ __html: eduProducts[2].title }}
                />
              </li>
              <li className="products-nav__menu-product">
                <Link
                  onClick={() => dispatch(toggleMenu())}
                  to={`/edu/producten/${eduProducts[3].titleInCode}`}
                  className="products-nav__menu-product-title"
                  dangerouslySetInnerHTML={{ __html: eduProducts[3].title }}
                />
              </li>
              <li className="products-nav__menu-product">
                <Link
                  onClick={() => dispatch(toggleMenu())}
                  to={`/edu/producten/${eduProducts[4].titleInCode}`}
                  className="products-nav__menu-product-title"
                  dangerouslySetInnerHTML={{ __html: eduProducts[4].title }}
                />
              </li>
              <li className="products-nav__menu-product">
                <Link
                  onClick={() => dispatch(toggleMenu())}
                  to={`/edu/producten/${eduProducts[5].titleInCode}`}
                  className="products-nav__menu-product-title"
                  dangerouslySetInnerHTML={{ __html: eduProducts[5].title }}
                />
              </li>
              <li className="products-nav__menu-product">
                <Link
                  onClick={() => dispatch(toggleMenu())}
                  to={`/edu/producten/${eduProducts[6].titleInCode}`}
                  className="products-nav__menu-product-title"
                  dangerouslySetInnerHTML={{ __html: eduProducts[6].title }}
                />
              </li>
              <li className="products-nav__menu-product">
                <Link
                  onClick={() => dispatch(toggleMenu())}
                  to={`/edu/producten/${eduProducts[7].titleInCode}`}
                  className="products-nav__menu-product-title"
                  dangerouslySetInnerHTML={{ __html: eduProducts[7].title }}
                />
              </li>
            </ul>

            <Link
              onClick={() => dispatch(toggleMenu())}
              to={`/edu`}
              className="products-nav__jumper"
            >
              <span className="btn-circle">
                <i className="icon-arrow-right"></i>
              </span>
              Alle producten
            </Link>
          </div>
        </div>
      </div>

      <div
        className="menu"
        id="workplace"
        style={
          props.clickedSector === "edu"
            ? { display: "none" }
            : props.clickedSector === "mobile"
            ? { display: "none" }
            : { display: "block" }
        }
      >
        <div className="menu-header">
          <Link
            onClick={() => dispatch(toggleMenu())}
            to={`/workplace`}
            className="menu__title"
          >
            Workplace
          </Link>

          <label className="hamburger" htmlFor="check">
            <input type="checkbox" id="check" checked readOnly />
            <span></span>
            <span></span>
            <span></span>
          </label>
        </div>

        <h3 className="menu__motto">
          Leerervaringen voor workplace learning.
        </h3>

        <div className="menu__wrapper">
          <div className="services-nav">
            <Link
              onClick={() => dispatch(toggleMenu())}
              to={`/workplace`}
              className="services-nav__title"
            >
              Diensten
            </Link>

            <ul className="services-nav__menu">
              <li className="services-nav__menu-service services-nav__menu-service--research">
                <Link
                  onClick={() => dispatch(toggleMenu())}
                  to={`/workplace/diensten/${workplaceServices[0].titleInCode}`}
                  className="services-nav__menu-service-title"
                >
                  <span
                    dangerouslySetInnerHTML={{ __html: workplaceServices[0].title }}
                  ></span>{" "}
                  <span className="services-nav__menu-service-baseline">
                    van leernoden
                  </span>
                </Link>
              </li>
              <li className="services-nav__menu-service services-nav__menu-service--strategy">
                <Link
                  onClick={() => dispatch(toggleMenu())}
                  to={`/workplace/diensten/${workplaceServices[1].titleInCode}`}
                  className="services-nav__menu-service-title"
                >
                  <span
                    dangerouslySetInnerHTML={{ __html: workplaceServices[1].title }}
                  ></span>{" "}
                  <span className="services-nav__menu-service-baseline">
                    voor leerprocessen en -producten
                  </span>
                </Link>
              </li>
              <li className="services-nav__menu-service services-nav__menu-service--product-design">
                <Link
                  onClick={() => dispatch(toggleMenu())}
                  to={`/workplace/diensten/${workplaceServices[2].titleInCode}`}
                  className="services-nav__menu-service-title"
                >
                  <span
                    dangerouslySetInnerHTML={{ __html: workplaceServices[2].title }}
                  ></span>{" "}
                  <span className="services-nav__menu-service-baseline">
                    van concept tot realisatie
                  </span>
                </Link>
              </li>
              <li className="services-nav__menu-service services-nav__menu-service--content-creation">
                <Link
                  onClick={() => dispatch(toggleMenu())}
                  to={`/workplace/diensten/${workplaceServices[3].titleInCode}`}
                  className="services-nav__menu-service-title"
                >
                  <span
                    dangerouslySetInnerHTML={{ __html: workplaceServices[3].title }}
                  ></span>{" "}
                  <span className="services-nav__menu-service-baseline">
                    voor leerproducten
                  </span>
                </Link>
              </li>
              <li className="services-nav__menu-service services-nav__menu-service--implementation">
                <Link
                  onClick={() => dispatch(toggleMenu())}
                  to={`/workplace/diensten/${workplaceServices[4].titleInCode}`}
                  className="services-nav__menu-service-title"
                >
                  <span
                    dangerouslySetInnerHTML={{ __html: workplaceServices[4].title }}
                  ></span>
                  <span className="services-nav__menu-service-baseline">
                    via coaching en training
                  </span>
                </Link>
              </li>
            </ul>

            <Link
              onClick={() => dispatch(toggleMenu())}
              to={`/workplace`}
              className="services-nav__jumper"
            >
              <span className="btn-circle">
                <i className="icon-arrow-right"></i>
              </span>
              Alle diensten
            </Link>
          </div>

          <div className="products-nav">
            <Link
              onClick={() => dispatch(toggleMenu())}
              to={`/workplace`}
              className="products-nav__title"
            >
              Leerproducten
            </Link>

            <ul className="products-nav__menu">
              <li className="products-nav__menu-product">
                <Link
                  onClick={() => dispatch(toggleMenu())}
                  to={`/workplace/producten/${workplaceProducts[0].titleInCode}`}
                  className="products-nav__menu-product-title"
                  dangerouslySetInnerHTML={{ __html: workplaceProducts[0].title }}
                />
              </li>
              <li className="products-nav__menu-product">
                <Link
                  onClick={() => dispatch(toggleMenu())}
                  to={`/workplace/producten/${workplaceProducts[1].titleInCode}`}
                  className="products-nav__menu-product-title"
                  dangerouslySetInnerHTML={{ __html: workplaceProducts[1].title }}
                />
              </li>
              <li className="products-nav__menu-product">
                <Link
                  onClick={() => dispatch(toggleMenu())}
                  to={`/workplace/producten/${workplaceProducts[2].titleInCode}`}
                  className="products-nav__menu-product-title"
                  dangerouslySetInnerHTML={{ __html: workplaceProducts[2].title }}
                />
              </li>
              <li className="products-nav__menu-product">
                <Link
                  onClick={() => dispatch(toggleMenu())}
                  to={`/workplace/producten/${workplaceProducts[3].titleInCode}`}
                  className="products-nav__menu-product-title"
                  dangerouslySetInnerHTML={{ __html: workplaceProducts[3].title }}
                />
              </li>
              <li className="products-nav__menu-product">
                <Link
                  onClick={() => dispatch(toggleMenu())}
                  to={`/workplace/producten/${workplaceProducts[4].titleInCode}`}
                  className="products-nav__menu-product-title"
                  dangerouslySetInnerHTML={{ __html: workplaceProducts[4].title }}
                />
              </li>
              <li className="products-nav__menu-product">
                <Link
                  onClick={() => dispatch(toggleMenu())}
                  to={`/workplace/producten/${workplaceProducts[5].titleInCode}`}
                  className="products-nav__menu-product-title"
                  dangerouslySetInnerHTML={{ __html: workplaceProducts[5].title }}
                />
              </li>
              <li className="products-nav__menu-product">
                <Link
                  onClick={() => dispatch(toggleMenu())}
                  to={`/workplace/producten/${workplaceProducts[6].titleInCode}`}
                  className="products-nav__menu-product-title"
                  dangerouslySetInnerHTML={{ __html: workplaceProducts[6].title }}
                />
              </li>
              <li className="products-nav__menu-product">
                <Link
                  onClick={() => dispatch(toggleMenu())}
                  to={`/workplace/producten/${workplaceProducts[7].titleInCode}`}
                  className="products-nav__menu-product-title"
                  dangerouslySetInnerHTML={{ __html: workplaceProducts[7].title }}
                />
              </li>
            </ul>

            <Link
              onClick={() => dispatch(toggleMenu())}
              to={`/workplace`}
              className="products-nav__jumper"
            >
              <span className="btn-circle">
                <i className="icon-arrow-right"></i>
              </span>
              Alle producten
            </Link>
          </div>
        </div>
      </div>

      <div
        className="menu"
        id="mobile"
        style={
          props.clickedSector === "workplace"
            ? { display: "none" }
            : props.clickedSector === "edu"
            ? { display: "none" }
            : { display: "block" }
        }
      >
        <div className="mobile-menu">
          <div className="mobile-menu-header">
            <Link
              onClick={() => dispatch(toggleMenu())}
              to={"/"}
              className="logo"
            >
              <img className="logo__aap" src={aap} alt="Oetang" />
            </Link>
            <label className="hamburger" htmlFor="check">
              <input type="checkbox" id="check" checked readOnly />
              <span></span>
              <span></span>
              <span></span>
            </label>
          </div>

          <ul className="mobile-menu__main-nav">
            <li className="mobile-menu__main-nav-item">
              <Link onClick={() => dispatch(toggleMenu())} to={`/edu`}>
                Education
              </Link>
              <ul className="mobile-menu__main-nav-sub">
                <li className="mobile-menu__main-nav-item-sub">
                  <Link onClick={() => dispatch(toggleMenu())} to={`/edu`}>
                    Diensten
                  </Link>
                </li>
                <li className="mobile-menu__main-nav-item-sub">
                  <Link onClick={() => dispatch(toggleMenu())} to={`/edu`}>
                    Leerproducten
                  </Link>
                </li>
              </ul>
            </li>
            <li className="mobile-menu__main-nav-item">
              <Link onClick={() => dispatch(toggleMenu())} to={`/workplace`}>
                Workplace
              </Link>
              <ul className="mobile-menu__main-nav-sub">
                <li className="mobile-menu__main-nav-item-sub">
                  <Link onClick={() => dispatch(toggleMenu())} to={`/workplace`}>
                    Diensten
                  </Link>
                </li>
                <li className="mobile-menu__main-nav-item-sub">
                  <Link
                    onClick={() => dispatch(toggleMenu())}
                    to={`/workplace`}
                    className="services-nav__title"
                  >
                    Leerproducten
                  </Link>
                </li>
              </ul>
            </li>
            <li className="mobile-menu__main-nav-item">
              <Link onClick={() => dispatch(toggleMenu())} to={`/portfolio`}>
                Portfolio
              </Link>
            </li>
            <li className="mobile-menu__main-nav-item">
              <Link onClick={() => dispatch(toggleMenu())} to={`/over-ons`}>
                Over ons
              </Link>
            </li>
            <li className="mobile-menu__main-nav-item">
              <Link onClick={() => dispatch(toggleMenu())} to={`/jobs`}>
                Jobs
              </Link>
              <div className="vacancies-count">
                <span>{vacancies}</span>
              </div>
            </li>
            <li className="mobile-menu__main-nav-item">
              <Link onClick={() => dispatch(toggleMenu())} to={`/contact`}>
                Contact
              </Link>
            </li>
            <li className="mobile-menu__main-nav-item mobile-menu__main-nav-item--training">
              <Link to={`https://trainingen.oetang.be/`} target="_blank">
                Nascholing
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Menu;
