import React, {Component} from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";

import Simon from "../assets/imgs/team/SIMON.jpg";
import Rose from "../assets/imgs/team/ROSE.jpg";
import Niels from "../assets/imgs/team/NIELS.jpg";
import Thomas from "../assets/imgs/team/THOMAS.jpg";
import Louise from "../assets/imgs/team/LOUISE.jpg";
import Aline from "../assets/imgs/team/ALINE.jpg";
import Joyce from "../assets/imgs/team/JOYCE.jpg";
import Sarah from "../assets/imgs/team/SARAH.jpg";
import Marthe from "../assets/imgs/team/MARTHE.jpg";
import Hebe from "../assets/imgs/team/HEBE.jpg";
import Jana from "../assets/imgs/team/JANA.jpg";
import Monkey from "../assets/imgs/team/MONKEY.jpg";

import LinkedIn from "../assets/imgs/team/linkedin-logo.svg";
import AboutUs from "../assets/shapes/about-us-circle.svg";
import StudioLife from "../components/StudioLife";
import Helmet from "react-helmet";
import {Link} from "react-router-dom";

class OverOns extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <div id="over-ons">
                <Helmet>
                    <title>Over ons</title>
                    <meta
                        name="description"
                        content="Oetang is een multidisciplinaire ontwerpstudio van leerervaringen. We werken volgens de methode van Learning Experience Design (LXD) waarbij we learner centered aan de slag gaan om leerproducten en leeroplossingen te ontwerpen. We doen dit zowel voor educatieve als professionele leeromgevingen."
                    ></meta>
                    <meta
                        name="keywords"
                        content="learning, leerervaring, design, leren, lxd, leerproduct, educatie, workplace, business, learning &amp; development, onderwijs, erfgoed, lifelong learning, learning experience design"
                    ></meta>
                </Helmet>
                <Header onMenuToggle={this.toggleMobileMenu} headerBG="green" />
                <section className="about-us">
                    <h1 className="about-us__title">
                        Oetang is een Learning Experience Design Studio
                    </h1>
                    <i className="icon-arrow-down"></i>
                </section>
                <section className="about-us-details">
                    <div className="about-us-details__wrapper">
                        <div className="about-us-details__info">
                            <div>
                                <h2 className="about-us-details__baseline">
                                    De{" "}
                                    <span>
                                        Learning<br></br> Experience Design
                                        <br></br>
                                    </span>{" "}
                                    Studio
                                </h2>
                            </div>

                            <div className="about-us-details__text">
                                <p className="about-us-details__baseText">
                                    Oetang is een multidisciplinaire
                                    ontwerpstudio van leerervaringen. We werken
                                    volgens de methode van Learning Experience
                                    Design (LXD) waarbij we{" "}
                                    <i>learner centered</i> aan de slag gaan om
                                    leerproducten en leeroplossingen te
                                    ontwerpen. We doen dit zowel voor educatieve
                                    als professionele leeromgevingen.
                                </p>

                                <p className="about-us-details__baseText">
                                    Als Learning Designers werken we onderbouwd
                                    en steunen we op jarenlange ervaring in
                                    didactiek, productontwikkeling en
                                    vormgeving. Oetang brengt leerinhouden tot
                                    leven zodat ze krachtige{" "}
                                    <i>learning experiences</i> worden.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>

                <div id="team-learning-designers" className="team">
                    <h2>Learning Designers</h2>
                    <div className="team-wrapper">
                        <div className="team-member">
                            <div className="team-member__pic">
                                <img src={Simon} />
                                <div className="team-member__pic-overlay"></div>
                            </div>
                            <div className="team-member__info">
                                <a
                                    href="https://www.linkedin.com/in/simon-vandekerckhove-b9375618a"
                                    className="team-member__linkedin"
                                    target="_blank"
                                >
                                    <img src={LinkedIn} />
                                </a>
                                <div className="team-member__name">
                                    Simon <span>Vandekerckhove</span>
                                </div>
                                <div className="team-member__function">
                                    bestuurder <br></br> &amp;
                                    conceptontwikkelaar
                                </div>
                            </div>
                        </div>

                        <div className="team-member">
                            <div className="team-member__pic">
                                <img src={Niels} />
                                <div className="team-member__pic-overlay"></div>
                            </div>
                            <div className="team-member__info">
                                <a
                                    href="#"
                                    className="team-member__linkedin"
                                    target="_blank"
                                >
                                    <img src={LinkedIn} />
                                </a>
                                <div className="team-member__name">
                                    Niels <span>Ribbens</span>
                                </div>
                                <div className="team-member__function">
                                    front-end development <br></br>&amp; motion
                                </div>
                            </div>
                        </div>

                        <div className="team-member">
                            <div className="team-member__pic">
                                <img src={Thomas} />
                                <div className="team-member__pic-overlay"></div>
                            </div>
                            <div className="team-member__info">
                                <a
                                    href="https://www.linkedin.com/in/thomas-velle/"
                                    className="team-member__linkedin"
                                    target="_blank"
                                >
                                    <img src={LinkedIn} />
                                </a>
                                <div className="team-member__name">
                                    Thomas <span>Velle</span>
                                </div>
                                <div className="team-member__function">
                                    concept- en productontwikkeling
                                </div>
                            </div>
                        </div>

                        <div className="team-member">
                            <div className="team-member__pic">
                                <img src={Rose} />
                                <div className="team-member__pic-overlay"></div>
                            </div>
                            <div className="team-member__info">
                                <a
                                    href="https://www.linkedin.com/in/rthys/"
                                    className="team-member__linkedin"
                                    target="_blank"
                                >
                                    <img src={LinkedIn} />
                                </a>
                                <div className="team-member__name">
                                    Rose <span>Thys</span>
                                </div>
                                <div className="team-member__function">
                                    concept- en productontwikkeling
                                </div>
                            </div>
                        </div>

                        <div className="team-member">
                            <div className="team-member__pic">
                                <img src={Louise} />
                                <div className="team-member__pic-overlay"></div>
                            </div>
                            <div className="team-member__info">
                                <a
                                    href="https://www.linkedin.com/in/louise-vandermeulen-37a642202/"
                                    className="team-member__linkedin"
                                    target="_blank"
                                >
                                    <img src={LinkedIn} />
                                </a>
                                <div className="team-member__name">
                                    Louise <span>Vandermeulen</span>
                                </div>
                                <div className="team-member__function">
                                    concept- en productontwikkeling
                                </div>
                            </div>
                        </div>

                        <div className="team-member">
                            <div className="team-member__pic">
                                <img src={Aline} />
                                <div className="team-member__pic-overlay"></div>
                            </div>
                            <div className="team-member__info">
                                <a
                                    href="https://www.linkedin.com/in/aline-adins-68597b162/"
                                    className="team-member__linkedin"
                                    target="_blank"
                                >
                                    <img src={LinkedIn} />
                                </a>
                                <div className="team-member__name">
                                    Aline<span> Adins</span>
                                </div>
                                <div className="team-member__function">
                                    grafisch en digitaal ontwerper
                                </div>
                            </div>
                        </div>

                        <div className="team-member">
                            <div className="team-member__pic">
                                <img src={Joyce} />
                                <div className="team-member__pic-overlay"></div>
                            </div>
                            <div className="team-member__info">
                                <a
                                    href="https://www.linkedin.com/in/joycedevoogt/"
                                    className="team-member__linkedin"
                                    target="_blank"
                                >
                                    <img src={LinkedIn} />
                                </a>
                                <div className="team-member__name">
                                    Joyce <span>Devoogt</span>
                                </div>
                                <div className="team-member__function">
                                    concept- en productontwikkeling
                                </div>
                            </div>
                        </div>

                        <div className="team-member">
                            <div className="team-member__pic">
                                <img src={Sarah} />
                                <div className="team-member__pic-overlay"></div>
                            </div>
                            <div className="team-member__info">
                                <a
                                    href="https://www.linkedin.com/in/sarah-goderis-17997984/"
                                    className="team-member__linkedin"
                                    target="_blank"
                                >
                                    <img src={LinkedIn} />
                                </a>
                                <div className="team-member__name">
                                    Sarah <span>Goderis</span>
                                </div>
                                <div className="team-member__function">
                                    concept- en productontwikkeling
                                </div>
                            </div>
                        </div>

                        <div className="team-member">
                            <div className="team-member__pic">
                                <img src={Marthe} />
                                <div className="team-member__pic-overlay"></div>
                            </div>
                            <div className="team-member__info">
                                <a
                                    href="https://be.linkedin.com/in/marthe-de-baets-951b19292"
                                    className="team-member__linkedin"
                                    target="_blank"
                                >
                                    <img src={LinkedIn} />
                                </a>
                                <div className="team-member__name">
                                    Marthe <span>De Baets</span>
                                </div>
                                <div className="team-member__function">
                                    concept- en productontwikkeling
                                </div>
                            </div>
                        </div>

                        <div className="team-member">
                            <div className="team-member__pic">
                                <img src={Hebe} />
                                <div className="team-member__pic-overlay"></div>
                            </div>
                            <div className="team-member__info">
                                <a
                                    href="https://www.linkedin.com/in/hebe-van-stappen-b0b179207/"
                                    className="team-member__linkedin"
                                    target="_blank"
                                >
                                    <img src={LinkedIn} />
                                </a>
                                <div className="team-member__name">
                                    Hebe <span>Van Stappen</span>
                                </div>
                                <div className="team-member__function">
                                    concept- en productontwikkeling
                                </div>
                            </div>
                        </div>

                        <div className="team-member">
                            <div className="team-member__pic">
                                <img src={Jana} />
                                <div className="team-member__pic-overlay"></div>
                            </div>
                            <div className="team-member__info">
                                <a
                                    href="https://www.linkedin.com/in/jana-elst-9b9598207/"
                                    className="team-member__linkedin"
                                    target="_blank"
                                >
                                    <img src={LinkedIn} />
                                </a>
                                <div className="team-member__name">
                                    Jana <span>Elst</span>
                                </div>
                                <div className="team-member__function">
                                    jobstudent concept- en productontwikkeling
                                </div>
                            </div>
                        </div>

                        <div className="team-member">
                            <div className="team-member__pic">
                                <img src={Monkey} />
                                <div className="team-member__pic-overlay"></div>
                            </div>
                            <div className="team-member__info">
                                <div className="team-member__name team-member__name--small">
                                    Join the jungle!
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="team-believe">
                    <div className="team-believe__img">
                        <img src={AboutUs} />
                    </div>
                    <div className="team-believe__text">
                        <h2>De kracht van leren</h2>
                        <p>
                            Oetang gelooft in de kracht van (levenslang) leren.
                            Leren gaat over groeien en ontwikkelen, over
                            nieuwsgierigheid en ambitie. Leren doen we altijd en
                            overal, in formele en informele contexten.
                        </p>
                        <p>
                            Oetang is gepassioneerd door positieve
                            leerervaringen met betekenis en impact voor de
                            leerder, jong of ouder. Zo zetten onze leerproducten
                            een wezenlijke verandering in gang. Want leren ...
                            dat is veranderen.
                        </p>
                        <p>
                            Oetang is zelf ook een lerende organisatie. Elke
                            project nodigt ons uit om nieuwe horizonten te
                            verkennen, onszelf te bevragen en bij te leren. Onze
                            Learning Design Studio is constant in beweging.
                        </p>
                    </div>
                </div>

                <div className="team-who-else">
                    <div className="team-who-else__wrapper">
                        <h2>En onze onderwijstrainers?</h2>

                        <div className="team-who-else__text">
                            <div>
                                <p>
                                    Oetang werkt voor haar eigen nascholingsaanbod met een gedreven team onderwijstrainers. Ze hebben een hart voor leren en een brede expertise in het onderwijsveld.
                                </p>

                                <p>
                                    Onze onderwijstrainers gaan met schoolteams
                                    aan de slag om te leren en te veranderen.
                                    Samen nemen we hindernissen en onderzoeken
                                    we hoe we de implementatie op de werkvloer
                                    kunnen realiseren.
                                </p>
                            </div>

                            <div>
                                <p>
                                    Onze trainingen en trajecten voor onderwijsteams kregen hier een  <a
                                        href="https://trainingen.oetang.be/"
                                        target="_blank"
                                    >nieuwe webstek</a>.
                                </p>

                                <div className="team-who-else__buttons">
                                    <a
                                        className="btn-red"
                                        href="https://trainingen.oetang.be/"
                                        target="_blank"
                                    >
                                        Bekijk trainingen{" "}
                                        <i className="icon-external"></i>
                                    </a>

                                    <a
                                        className="btn-red"
                                        href="https://trainingen.oetang.be/#/onderwijsteam"
                                        target="_blank"
                                    >
                                        Onderwijstrainers{" "}
                                        <i className="icon-external"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="team-trainers" className="team d-none">
                    <h2>Trainers</h2>
                    <div className="team-wrapper">
                        <div className="team-member">
                            <div className="team-member__pic">
                                <img src={Simon} />
                                <div className="team-member__pic-overlay"></div>
                            </div>

                            <div className="team-member__name">Klaas</div>
                            <div className="team-member__function">
                                trainer onderwijs
                            </div>
                            <a
                                href="mailto:klaas@oetang.be"
                                className="team-member__email"
                            >
                                klaas@oetang.be
                            </a>
                        </div>

                        <div className="team-member">
                            <div className="team-member__pic">
                                <img src={Simon} />
                                <div className="team-member__pic-overlay"></div>
                            </div>
                            <div className="team-member__name">Rozemie</div>
                            <div className="team-member__function">
                                trainer onderwijs
                            </div>
                            <a
                                href="mailto:rozemie@oetang.be"
                                className="team-member__email"
                            >
                                rozemie@oetang.be
                            </a>
                        </div>

                        <div className="team-member">
                            <div className="team-member__pic">
                                <img src={Simon} />
                                <div className="team-member__pic-overlay"></div>
                            </div>
                            <div className="team-member__name">Yasmin</div>
                            <div className="team-member__function">
                                trainer onderwijs
                            </div>
                            <a
                                href="mailto:yasmin@oetang.be"
                                className="team-member__email"
                            >
                                yasmin@oetang.be
                            </a>
                        </div>

                        <div className="team-member">
                            <div className="team-member__pic">
                                <img src={Simon} />
                                <div className="team-member__pic-overlay"></div>
                            </div>
                            <div className="team-member__name">Lien</div>
                            <div className="team-member__function">
                                trainer onderwijs
                            </div>
                            <a
                                href="mailto:lien@oetang.be"
                                className="team-member__email"
                            >
                                lien@oetang.be
                            </a>
                        </div>

                        <div className="team-member">
                            <div className="team-member__pic">
                                <img src={Simon} />
                                <div className="team-member__pic-overlay"></div>
                            </div>
                            <div className="team-member__name">Tine</div>
                            <div className="team-member__function">
                                trainer onderwijs
                            </div>
                            <a
                                href="mailto:tine@oetang.be"
                                className="team-member__email"
                            >
                                tine@oetang.be
                            </a>
                        </div>

                        <div className="team-member">
                            <div className="team-member__pic">
                                <img src={Simon} />
                                <div className="team-member__pic-overlay"></div>
                            </div>
                            <div className="team-member__name">Silke</div>
                            <div className="team-member__function">
                                trainer onderwijs
                            </div>
                            <a
                                href="mailto:silke@oetang.be"
                                className="team-member__email"
                            >
                                silke@oetang.be
                            </a>
                        </div>

                        <div className="team-member">
                            <div className="team-member__pic">
                                <img src={Simon} />
                                <div className="team-member__pic-overlay"></div>
                            </div>
                            <div className="team-member__name">Jonas</div>
                            <div className="team-member__function">
                                trainer onderwijs
                            </div>
                            <a
                                href="mailto:jonas@oetang.be"
                                className="team-member__email"
                            >
                                jonas@oetang.be
                            </a>
                        </div>
                    </div>
                </div>

                <Footer
                    page="home"
                    cta="Dus, wanneer <span>beginnen we?</span>"
                ></Footer>
            </div>
        );
    }
}

export default OverOns;