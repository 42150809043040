import React, { useState } from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import Helmet from "react-helmet";
import { HashLink } from "react-router-hash-link";
import Obfuscate from "react-obfuscate";
import VacancyFooter from "../../components/vacancies/VacancyFooter";

const Vacancy2024 = () => {
  return (
    <div id="vacancy">
      <Helmet>
        <title>Vacature</title>
        <meta
          name="description"
          content="Oetang zoekt ..."
        ></meta>
        <meta
          name="keywords"
          content="vacature, learning designer, conceptontwikkelaar, productontwikkelaar, job, sollicitatie, solliciteren, learning, leerervaring, design, leren, lxd, leerproduct, educatie, workplace, business, learning &amp; development, onderwijs, lifelong learning, learning experience design"
        ></meta>
      </Helmet>
      <Header headerBG="green"></Header>
      <section className="vacancy-hero">
        <h3 className="vacancy-hero__type">VACATURE</h3>
        <h1 className="vacancy-hero__title">UX/UI Designer</h1>
        <h2 className="vacancy-hero__subtitle">
        met ervaring in Design Systems
        </h2>
        <i className="icon-arrow-down"></i>
      </section>
      <section className="vacancy-details">
        <div className="vacancy-details__wrapper">
          <div className="vacancy-details__info">
            <div>
              <h2 className="vacancy-details__baseline">
              Ben jij de UI/UX Designer <br></br>die Oetang zoekt?
              </h2>
            </div>

            <div className="vacancy-details__text">
              <p className="vacancy-details__baseText">
              Bij Oetang maken we impact door het ontwerpen van innovatieve (digitale en fysieke) <strong>leerproducten en -oplossingen</strong>. Wij geloven in de kracht van levenslang leren en creëren voor kinderen, jongeren en volwassenen betekenisvolle leerervaringen.
              </p>

              <p className="vacancy__baseText">
              Voor onze Learning Design Studio in Gent zoeken we een getalenteerde UI/UX Designer met ervaring in Design Systems. Je werkt mee aan de vernieuwing van een <strong>educatief webplatform</strong>. De samenwerking kan op freelance basis of via een tijdelijk contract.
              </p>
            </div>
          </div>
          <div className="vacancy-details__info">
            <h3>Wat ga je doen?</h3>
            <p>
            Als UI/UX Designer speel je een cruciale rol in ons projectteam, waarbij je nauw samenwerkt met analisten, ontwikkelaars en testers. Jouw focus ligt op het vertalen van wireframes en functionele ideeën naar een <strong>intuïtief, consistent en goed gedocumenteerd Design System</strong> voor het educatief webplatform.
            </p>
            <ul>
              <li>Omzetten van wireframes en grafische voorstudies in een nieuw UI design.</li>
              <li>Sparren met functioneel analisten over UX-optimalisaties en user flows.</li>
              <li>In kaart brengen en uitwerken van (herbruikbare) stijlen en componenten.</li>
              <li>Ontwerpen van volledige schermdesigns met aandacht voor interactie en UI-states.</li>
              <li>Documenteren van het Design System voor ontwikkelaars.</li>
              <li>Aanpassen en verfijnen van een bestaande iconenset.</li>
            </ul>
            <h3>Wie ben jij?</h3>
            <ul>
              <li>Je hebt aantoonbare ervaring in UX/UI en met Design Systems.</li>
              <li>Je werkt nauwkeurig en consistent.</li>
              <li>Je herkent patronen, denkt in (de)composities en begrijpt de schaalbaarheid van componenten.</li>
              <li>Je kunt je designs vertalen naar duidelijke richtlijnen voor ontwikkelaars.</li>
              <li>Je staat open voor feedback van product owners, analisten, testers en gebruikers, en denkt in alternatieven.</li>
              <li>Je werkt zelfstandig na briefings en kunt zelf ook heldere briefings geven.</li>
              <li>Je hebt ervaring met het delen, optimaliseren en versioneren van designbestanden en prototypes, bij voorkeur in Adobe XD.</li>
            </ul>
            
            <h3>Wat nog?</h3>
            <p>
            Bij Oetang krijg je de kans om bij te dragen aan de ontwikkeling van een webplatform dat dagelijks door tienduizenden leerkrachten en leerlingen wordt bezocht. Hoewel je als UI/UX Designer geen leerspecialist hoeft te zijn, verwachten we wel een gezonde interesse in learning. Je toont betrokkenheid bij andere projecten van je collega’s, ook als je zelf aan één groot project werkt.          
            </p>
          </div>
          <div className="vacancy-details__info">
            <div>
              <h2 className="vacancy-details__baseline">Ons aanbod</h2>
            </div>

            <p className="vacancy-details__baseText">
            We bieden verschillende mogelijkheden aan, zowel op freelance basis als onder tijdelijk contract. Momenteel zijn we op zoek naar versterking voor twee periodes, elk met hun intensiteit:<br></br><br></br>
            <ul>
              <li>40 dagen, evenwichtig verspreid van half oktober tot half januari. Wat ongeveer overeenkomt met een 3/5de betrekking. </li>
              <li>35 dagen, evenwichtig verspreid van half januari tot eind juli. Wat ongeveer overeenkomt met 1,5 dag per week.</li>
            </ul>
            Bij Oetang werk je in een dynamisch team van Learning Designers en heb je regelmatig contact met ontwikkelaars en analisten van de opdrachtgever zelf.
            </p>
            <p className="vacancy-details__baseText">
              Oetang zet in op werkgeluk via het aanbieden van een unieke
              werkplek, naast het Wintercircus te Gent, marktconforme verloning
              in combinatie met een loonpakket op maat en een uitgebreid
              extralegaal pakket, flexibele werktijden, mobiliteitsoplossingen
              en plaatsonafhankelijk werken. Kortom, wij bieden je een fijne en
              uitdagende werkomgeving, waarin je je ten volle kan ontplooien.
            </p>
          </div>
        </div>
      </section>
      <section className="cta-banner">
        <h3 className="cta-banner__title">
          Mail naar jobs@oetang.be<br></br><br></br>
          <span>met je motivatie en CV.</span>
        </h3>
        <Obfuscate
          className="cta-banner__btn btn-green"
          email="jobs@oetang.be"
          obfuscateChildren={false}
        >
          <span className="long-text">
            Vertel ons waarom jij deze Oetang moet worden
          </span>
          <span className="short-text">Vertel ons meer</span>
          <i className="icon-contact"></i>
        </Obfuscate>
      </section>
      <VacancyFooter></VacancyFooter>
    </div>
  );
};

export default Vacancy2024;